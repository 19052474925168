import React from "react"
import { makeStyles } from "@material-ui/styles"
import { injectIntl } from "react-intl"
import { RichDescription, RichTitle } from ".."
import backgroundImg from "./backgroundImg.svg"

const styles = makeStyles(theme => {
  return {
    contactContainer: {
      backgroundColor: props =>
        props.herobannerColor
          ? props.herobannerColor
          : theme.palette.green.support,
      height: "462px",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      flexDirection: "column",
    },
    title: {
      color: theme.palette.green.main,
      fontFamily: theme.typography.fontFamily,
      fontSize: "44px",
      fontWeight: "300",
      textAlign: "center",
      marginTop: theme.spacing(0),
      [theme.breakpoints.down("md")]: {
        fontSize: "28px",
      },
      position: "relative",
      zIndex: "1",
    },
    text: {
      color: theme.palette.tertiary.main,
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      lineHeight: "28px",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        marginLeft: theme.spacing(2.5),
        marginRight: theme.spacing(2.5),
        fontSize: "16px",
      },
      position: "relative",
      zIndex: "1",
    },
    contactInfoContainer: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      marginTop: theme.spacing(5),
      marginLeft: theme.spacing(8.75),
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(2),
      },
    },
    backgroundImg: {
      position: "absolute",
      width: "550px",
      left: "10%",
      top: theme.spacing(19.4),
      color: "red",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },

    contactInfo: {
      display: "flex",
      flexDirection: "column",
      paddingRight: theme.spacing(13),
      fontWeight: "600",
      position: "relative",
      zIndex: "1",
      color: theme.palette.secondary.main,
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        paddingRight: theme.spacing(0),
        paddingTop: theme.spacing(3),
      },
      "& span": {
        marginTop: theme.spacing(2),
        "& a": {
          textDecoration: "none",
          color: theme.palette.secondary.main,
        },
      },
    },
  }
})

export default injectIntl(props => {
  const classes = styles()
  const email = props.emailLogo
  return (
    <div className={classes.contactContainer}>
      <div className={classes.contactTitleContainer}>
        <h1 className={classes.title}>
          {props.pageTitle && <RichTitle titleText={props.pageTitle} />}
        </h1>
        <span className={classes.text}>
          {props.pageDescription && (
            <RichDescription copyText={props.pageDescription} />
          )}
        </span>
      </div>
      <img src={backgroundImg} className={classes.backgroundImg} alt="" />
      <div className={classes.contactInfoContainer}>
        <div className={classes.contactInfo}>
          <img src={email} alt="" />
          <span>
            <a href={"mailto:" + props.email}>{props.email}</a>
          </span>
        </div>
      </div>
    </div>
  )
})
